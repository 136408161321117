<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Müşteri Bilgisi</b-card-title>
          </b-card-header>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th width="200">
                  Müşteri Adı
                </b-th>
                <b-td>{{ completedSaleDelivery.customer }}</b-td>
              </b-tr>
              <b-tr v-if="completedSaleDelivery.company_name">
                <b-th style="width: 200px">
                  Firma Ünvanı
                </b-th>
                <b-td>{{ completedSaleDelivery.company_name }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Telefon
                </b-th>
                <b-td>{{ completedSaleDelivery.phone }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  E-Posta
                </b-th>
                <b-td>{{ completedSaleDelivery.email }}</b-td>
              </b-tr>
            </b-thead>
          </b-table-simple>
        </b-card>
      </b-col>
      <b-col
        cols="12"
      >
        <b-card
          title="Teşekkür Mektubu"
          class="text-center"
        >
          <b-img
            v-if="completedSaleDelivery.photo"
            :src="$store.state.app.baseURL + '/media/sales/delivery/'+ completedSaleDelivery.photo"
            fluid
            class="width-250 mb-2"
          />
          <b-alert
            v-else
            variant="info"
            show
          >
            <div class="alert-body text-center">
              Teslimat Fotoğrafı Bulunamadı!
            </div>
          </b-alert>
          <b-alert
            :variant="completedSaleDelivery.mailsend === '1'? 'success' : 'danger'"
            show
          >
            <div class="alert-body text-center">
              {{ completedSaleDelivery.mailsend === '1'? 'Teşekkür Mektubu Gönderildi.' : 'Teşekkür Mektubu Gönderilmedi.!' }}
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Araç Bilgileri</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th width="200">
              Marka
            </b-th>
            <b-td>{{ completedSaleDelivery.brand }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Model
            </b-th>
            <b-td>{{ completedSaleDelivery.model }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Yakıt / Vites
            </b-th>
            <b-td>{{ completedSaleDelivery.fuel }} / {{ completedSaleDelivery.gear }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Donanım Paketi
            </b-th>
            <b-td>{{ completedSaleDelivery.hardware }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Model Yılı
            </b-th>
            <b-td>{{ completedSaleDelivery.myear }}</b-td>
          </b-tr>
        </b-thead>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Satış Föyü Bilgileri</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th width="200">
              Satış Föy No
            </b-th>
            <b-td>{{ completedSaleDelivery.order_number }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Satış Danışmanı
            </b-th>
            <b-td>{{ completedSaleDelivery.username }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Fatura No
            </b-th>
            <b-td>{{ completedSaleDelivery.sales_invoice_no }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Fatura Tarihi
            </b-th>
            <b-td>{{ moment(completedSaleDelivery.sales_invoice).format('LL') }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Teslimat Tarihi
            </b-th>
            <b-td>{{ moment(completedSaleDelivery.delivery).format('LL') }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Teslimat Yeri
            </b-th>
            <b-td>{{ completedSaleDelivery.location }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Araç Satış Bedeli
            </b-th>
            <b-td>
              <span v-if="completedSaleDelivery.invoice.aracsatisbedeli">
                {{ completedSaleDelivery.invoice.aracsatisbedeli | toCurrency }} ₺
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Ödenen Tutar
            </b-th>
            <b-td>
              <span v-if="completedSaleDelivery.invoice.aracsatisbedeli">
                {{ completedSaleDelivery.invoice.aracsatisbedeli | toCurrency }} ₺
              </span>
            </b-td>
          </b-tr>
          <!--          <b-tr>-->
          <!--            <b-th>-->
          <!--              Teslimat Tutarı-->
          <!--            </b-th>-->
          <!--            <b-td>-->
          <!--              <span v-if="completedSaleDelivery.delivery_price">-->
          <!--                {{ completedSaleDelivery.delivery_price }}-->
          <!--              </span>-->
          <!--              <span v-else>-->
          <!--                - -->
          <!--              </span>-->
          <!--              <div class="text-danger font-small-2">-->
          <!--                Bu alan satış destek personeli tarafından girilmektedir.-->
          <!--              </div>-->
          <!--            </b-td>-->
          <!--          </b-tr>-->
          <b-tr>
            <b-th>
              Toplam Satış Bedeli
            </b-th>
            <b-td>
              <span v-if="completedSaleDelivery.invoice.aracsatisbedeli">
                {{ completedSaleDelivery.invoice.aracsatisbedeli | toCurrency }} ₺
              </span>
            </b-td>
          </b-tr>
        </b-thead>
      </b-table-simple>
    </b-card>
    <div class="text-center">
      <b-button
        variant="warning"
        class="mr-2"
        @click="closeDelivery('interview')"
      >
        Görüşme Notu Oluştur ve Kapat
      </b-button>
      <b-button
        variant="success"
        @click="closeDelivery('onlyClose')"
      >
        Teslimat Görüşmesi Tamamla
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BTableSimple, BTh, BTd, BTr, BThead, BRow, BCol, BImg, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'DeliveryView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BTh,
    BTd,
    BTr,
    BThead,
    BRow,
    BCol,
    BImg,
    BButton,
    BAlert,
  },
  computed: {
    completedSaleDelivery() {
      return this.$store.getters['completedSaleDelivery/getCompletedSaleDelivery']
    },
  },
  watch: {
  },
  created() {
    this.getCompletedSaleDelivery()
  },
  methods: {
    getCompletedSaleDelivery() {
      this.$store.dispatch('completedSaleDelivery/completedSaleDeliveryView', this.$route.params.id)
    },
    closeDelivery(type) {
      this.$store.dispatch('completedSaleDelivery/closeDelivery', this.$route.params.id)
        .then(res => {
          if (res) {
            if (type === 'interview') {
              this.$router.push(`/interviews/add/${this.completedSaleDelivery.id_com_customer}`)
            } else {
              this.$router.push('/sale_deliveries/completed')
            }
          }
        })
    },
  },
}
</script>
